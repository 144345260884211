import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const Prices = ({ data: { tables } }) => {
  const { title, content, moneyTable } = tables;
  return (
    <Layout>
      <SEO title="Fotos" />
      <div className="site-container blogs-page">
        <div className="container">
          <div className="section-head">
            <h1 className="line-heading h2">{title}</h1>
          </div>
          <div className="card-deck mb-3 text-center">
            {moneyTable &&
              moneyTable.map(({ title, price, text1, text2 }, i) => {
                return (
                  <div className="card mb-4 box-shadow" key={i}>
                    <div className="card-header">
                      <h4 className="my-0 font-weight-normal">{title}</h4>
                    </div>
                    <div className="card-body">
                      <h1 className="card-title pricing-card-title">
                        {price} € <small className="text-muted">/ mes</small>
                      </h1>
                      <ul className="list-unstyled mt-3 mb-4">
                        <li>{text1}</li>
                        <li>{text2}</li>
                      </ul>
                    </div>
                  </div>
                );
              })}
          </div>
          <div
            className="post"
            dangerouslySetInnerHTML={{
              __html: content.childMarkdownRemark.html,
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Prices;

export const pageQuery = graphql`
  query MoneyQuery {
    tables: contentfulTarfiasYHorarios {
      id
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      moneyTable {
        title
        price
        text1
        text2
      }
    }
  }
`;
